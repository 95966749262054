<template>
  <div class="login-outter">
    <div class="login-cont">

      <el-tabs v-model="activeName" @tab-click="handleClick" style="padding-top: 20px;padding-left: 40px;padding-right: 40px">
        <el-tab-pane label="密码登录" name="first">
          <div class="login-form">
            <el-form :model="form" label-position="left" label-width="68px">

              <el-form-item label="账 号" class="loginLab">
                <el-input
                        size="medium"
                        v-model="form.userName"
                        auto-complete="off"
                        style="  width: 270px;"
                        placeholder="请输入账号"
                        clearable
                ></el-input>
              </el-form-item>

              <el-form-item label="密 码" class="loginLab">
                <el-input
                        size="medium"
                        type="password"
                        @keyup.enter.native="loginSubmit"
                        auto-complete="off"
                        v-model="form.password"
                        placeholder="请输入密码"
                        style="  width: 270px;"
                        clearable
                ></el-input>
              </el-form-item>
              <span class="forPas">忘记密码?</span>
              <div style="margin-top: 50px">
                <el-button  class="login-btn"  @click="loginSubmit" :loading="btnLoading"
                >登&nbsp; &nbsp;&nbsp; 录</el-button>
                <!--<div class="bottom">
                 <span class="register">点击注册</span>
                 <span class="forget">
                   忘记密码
                 </span>
               </div>-->
              </div>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="免密码登录" name="second">
          <div class="login-form">
            <el-form :model="form" label-position="left" label-width="75px">

              <el-form-item label="手机号" class="loginLab">
                <el-input
                        size="medium"
                        v-model="form.userName"
                        auto-complete="off"
                        style="  width: 263px;"
                        placeholder="请输入手机号"
                        clearable
                ></el-input>
              </el-form-item>

              <el-form-item label="验证码" class="loginLab">
                <el-input
                        size="medium"
                        type="password"

                        auto-complete="off"
                        v-model="form.password"
                        placeholder="请输入验证码"
                        style="  width: 150px;"
                        clearable
                ></el-input>
                <el-button class="btn-orange" :disabled="disabled" @click="getCode">{{valiBtn}}</el-button>
              </el-form-item>

              <div style="margin-top: 50px">
                <el-button  class="login-btn"  @click="loginSubmit" :loading="btnLoading"
                >登&nbsp; &nbsp;&nbsp; 录</el-button>
                <!--<div class="bottom">
                 <span class="register">点击注册</span>
                 <span class="forget">
                   忘记密码
                 </span>
               </div>-->
              </div>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>


      <div class="login-footer-span">

      </div>
    </div>


  </div>
</template>

<script>

import {
    llylogin,
} from "@/Api/login";
import { setToken,encrypt,decrypt } from "@/Util/auth";
import { setMenu, setUserId,setHandle } from "@/Util/store";

export default {
  data() {
    return {
      disabled:false,
      valiBtn:'获取验证码',
      activeName: 'first',
      type: "1",
      btnLoading: false,
      form: {
        userName: "",
        password: ""
      }
    };
  },
  methods: {
    // 初始化框架
    initDeel(to) {
      this.routerTraversing(this.getRoutArr, to.path, "");
      if (to.meta.hidden) {
        this.setItemActive(to.meta.pName);
      } else {
        this.setItemActive(to.name);
      }
      if (!to.meta.noTag) {
        if (to.name) {
          this.setTagActive(to.name);
          this.setTagView(to);
        }
      }
    },
    //登录的接口
    loginSubmit() {
      this.btnLoading = true;
        let obj = {
          username: this.form.userName,
          password: this.form.password

        };
        let ss= encrypt(JSON.stringify(obj))
        llylogin(ss).then(res => {
          debugger
          let dd= JSON.parse(decrypt(res))
          if (dd.code == 200) {
            if(dd.data.access=='2'){
              localStorage.setItem("shShow", 'true');
            }else {
              localStorage.setItem("shShow", 'false');
            }
            if(dd.data.access=='2' || dd.data.access=='0'){
              setToken(dd.data.token);
              // setUserId(res.uId);
              setMenu("menuFinance");
              this.$router.$avueRouter.formatRoutes();
              let routerArr = this.$router.$avueRouter.routerList;
              localStorage.setItem("username", this.form.userName);
              this.getSilderArr(routerArr);
              this.initDeel(this.$route);
              this.btnLoading = false;
              this.$router.push({ path: "/fhome" });
            }else {
              this.btnLoading = false;
              this.$message.error(`没有权限`);
            }

            }else {
                this.btnLoading = false;
                alert(dd.msg);
            }

          })
          .catch(() => {
            this.btnLoading = false;
          });

    },

    handleClick(){

    },
    getCode(){
      this.tackBtn()
    },
    tackBtn(){       //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if(time == 0){
          clearInterval(timer);
          this.valiBtn = '获取验证码';
          this.disabled = false;
        }else{
          this.disabled = true;
          this.valiBtn = time + '秒后重试';
          time--;
        }
      }, 1000);
    },
  },
    mounted() {

    }
};
</script>

<style lang="less" scoped>
.login-outter {
  width: 100%;
  height: 100%;
  background: url("./login.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.login-cont {
  position: absolute;
  z-index: 9999;
  width: 509px;
  height: 411px;
  background: #fff;
  right:280px ;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  .login-title {
    height: 135px;
    background: url('/static/images/logintop.png') no-repeat;
  background-size:100%;

  }
}
.login-form {
  padding-top: 36px;
  width: 337px;
  height: 279px;
  margin:  0 auto;
}
.login-btn {
  color: #fff;
  background: linear-gradient(90deg, #3171F4 0%, #4292F7 100%);
  border-radius: 27px;
  width: 100%;
  height: 55px;
  font-size: 18px;
}
.login-footer-span {
  font-size: 14px;
  width: 306px;
  margin: auto;
  color: #797979;
  margin-top: 5px;
  text-align: center;
}
.bottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 337px;
  font-size: 12px;
margin-top:22px;
}
.register{
  color:#059AFF ;
}

 /deep/.loginLab .el-input__inner{
          height: 47px;
              line-height: 47px;
        }
/deep/.loginLab .el-form-item__label{
   font-size: 16px;
   font-weight: 400;
 letter-spacing:5px;
   line-height:47px ;
 }
 .el-form-item{
    margin-bottom: 14px;
}
.forPas{
  font-size: 14px;
  float: right;
  cursor: pointer;
  color: #AAAAAA;
}
  .loginLab{
    padding-bottom: 10px;
  }
  .btn-orange{
    width: 108px;
    float: right;
    height: 47px;
  }
</style>
